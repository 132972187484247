import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo'

import { ContentWrapper } from '../components/Elements'
import { Button, Container, Divider, Header, Icon } from 'semantic-ui-react'

const privacyTemplate = ({ data }) => {
  
  const document = data.datoCmsInfoPage.content

  const { contentNode: { childMarkdownRemark: { html }}, link, title, linkButton, buttonLink, buttonText } = data.datoCmsInfoPage

  return (
    <Layout>
      <SEO title={ link } />  
      <ContentWrapper>
        <Container text textAlign='justified'>
          <Header size='huge' style={{
                  fontFamily: 'Playfair Display',
                  marginTop: '2rem',
                  marginBottom: '6rem'
                }}
                >{ title }</Header>
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </Container>
        <Divider hidden section />
        <Container textAlign='center'>
          {
            linkButton ? 
              <Link to={ buttonLink }>
                <Button color='teal' size='huge' calltoaction='true'>
                  { buttonText }
                  <Icon name='right arrow' />
                </Button>
              </Link>
              : null
          }
        </Container>
      </ContentWrapper>
    </Layout>
  );
};

export default privacyTemplate

export const pageQuery = graphql`
  query Strona($uid: String!) {
    datoCmsInfoPage (uid: { eq: $uid }) {
      id
      title
      link
      buttonLink
      buttonText
      linkButton
      uid
      contentNode {
        id
        childMarkdownRemark {
          id
          html
        }
      }
    }
  }
`;